import React from "react";
const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = { isLoggedIn: true, user: {}, hasLoadedSession: false };

  componentDidMount() {
    this.props.checkStorageForSession().then((res) => {
      this.setState({ ...res, hasLoadedSession: true });
    });
  }

  login = ({ user }, cb) => {
    this.setState({ isLoggedIn: true, user }, cb);
  };

  logout = (cb) => {
    this.setState({ isLoggedIn: false, user: {} }, cb);
  };

  updateUser = (user) => {
    const { onUserUpdated = () => {} } = this.props;

    this.setState({ user });
    onUserUpdated(user);
  };

  render() {
    const { user, hasLoadedSession, isLoggedIn } = this.state;
    const { login, logout, updateUser } = this;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          user,
          hasLoadedSession,
          isLoggedIn,
          login,
          logout,
          updateUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

function withAuthConsumer(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <AuthConsumer>
          {(props) => <WrappedComponent {...this.props} {...props} />}
        </AuthConsumer>
      );
    }
  };
}

export default AuthProvider;
export { AuthProvider, AuthConsumer, withAuthConsumer };
