import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { loginRequest } from "../utils/requests";
import { withAuthConsumer } from "../providers/AuthProvider";
import { Button, Input } from "../components";
import { ReactComponent as Logo } from "../statics/cursos-xavitristancho.svg";
import { ReactComponent as EmailIcon } from "../statics/icon-email.svg";
import { ReactComponent as PasswordIcon } from "../statics/icon-password.svg";
import loginBackground from "../statics/bg_login.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(${loginBackground});
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  background-color: #333333;
  padding: 35px;
`;

const Login = ({ login }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    loginRequest(credentials)
      .promise.then(({ token, ...user }) => {
        login({ user });
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
      })
      .catch(({ error }) => setError(error));
  };

  const onInputChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <Container>
      <Form style={{ borderRadius: 15 }} onSubmit={onSubmit}>
        <Logo
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Input
          Icon={EmailIcon}
          type="email"
          name="email"
          id="email"
          placeholder="correo electrónico"
          onChange={onInputChange}
          value={credentials.email}
        />
        <Input
          Icon={PasswordIcon}
          type="password"
          name="password"
          id="password"
          placeholder="contraseña"
          onChange={onInputChange}
          value={credentials.password}
        />
        {error && <p style={{ color: "white" }}>{error.message}</p>}
        <Button style={{ marginTop: 50 }} type="submit">
          Acceder
        </Button>
      </Form>
    </Container>
  );
};

export default withAuthConsumer(Login);
