import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../statics/cursos-xavitristancho.svg";

const NavBarContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 60px;
  background-color: #fd3365;
`;

const CustomNavBar = styled.nav`
  display: flex;
  align-items: center;
  width: 80%;
`;

const NavBar = () => {
  return (
    <NavBarContainer>
      <CustomNavBar>
        <Logo />
      </CustomNavBar>
    </NavBarContainer>
  );
};

export default NavBar;
