import React from "react";
import AuthApp from "./apps/AuthApp";
import UnAuthApp from "./apps/UnAuthApp";
import { AuthProvider, AuthConsumer } from "./providers/AuthProvider";

function App() {
  return (
    <AuthProvider
      checkStorageForSession={() => {
        const isLoggedIn = localStorage.getItem("token") !== null;

        try {
          const user = JSON.parse(localStorage.getItem("user"));

          return Promise.resolve({ isLoggedIn, user });
        } catch (e) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");

          return Promise.resolve({ isLoggedIn: false, user: null });
        }
      }}
      onUserUpdated={(user) => {
        localStorage.setItem("user", JSON.stringify(user));
      }}
    >
      <AuthConsumer>
        {({ hasLoadedSession, isLoggedIn }) =>
          hasLoadedSession ? (
            isLoggedIn ? (
              <AuthApp />
            ) : (
              <UnAuthApp />
            )
          ) : (
            <div></div>
          )
        }
      </AuthConsumer>
    </AuthProvider>
  );
}

export default App;
