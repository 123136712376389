import styled from "styled-components";

const Button = styled.button`
  padding-top: 14px;
  padding-right: 25px;
  padding-bottom: 12px;
  padding-left: 25px;
  background-color: #fd3365;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  :hover {
    background-color: #ff285b;
  }
`;

export default Button;
