import request from "./request";

export const getClassesRequest = () => request("courses/1/classes");
export const getClassFeedbackRequest = classId =>
  request(`classes/${classId}/feedback`);

export const updateFeedbackRequest = ({ classId, ...body }) =>
  request(`classes/${classId}/feedback`, {
    method: "PATCH",
    body: JSON.stringify(body)
  });

export const loginRequest = credentials =>
  request("users/login", {
    method: "POST",
    body: JSON.stringify({ ...credentials })
  });
