import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ReactComponent as ClockIcon } from "../statics/icon-clock.svg";
import { ReactComponent as PlayIcon } from "../statics/icon-play.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;

  ${({ active }) => (active ? `background-color: #fd3365` : ``)}
`;

const NumberContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CustomClockIcon = styled(ClockIcon)`
  path {
    fill: white;
  }
`;

const Video = ({ number, duration, title, active }) => {
  const history = useHistory();

  const goToNextClass = () => {
    history.push(`/clase/${number}`);
  };

  return (
    <Container active={active} onClick={goToNextClass}>
      <NumberContainer>
        {active ? <PlayIcon /> : <span>{number}</span>}
      </NumberContainer>
      <InfoContainer>
        <span>{title}</span>
        <Duration>
          <CustomClockIcon style={{ marginRight: 10 }} />
          {duration}
        </Duration>
      </InfoContainer>
    </Container>
  );
};

export default Video;
