import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
// import Rating from "react-rating";
import styled from "styled-components";
import {
  getClassesRequest,
  // getClassFeedbackRequest,
  // updateFeedbackRequest,
} from "../utils/requests";
import { useParams } from "react-router-dom";
import { Video } from "../components";

const Page = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #3b2e34;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #252525;
  padding: 10px;
  border-radius: 8px;

  @media only screen and (min-width: 1440px) {
    flex-direction: row;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 0;
  width: 100%;

  ::before {
    height: 0px;
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const VideoNavigationContainer = styled.div`
  width: 100%;
  background-color: black;
  color: white;

  @media only screen and (min-width: 1440px) {
    width: 30%;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  @media only screen and (min-width: 1440px) {
    height: 100%;
  }
`;

const VideoNavigation = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: auto;
  max-height: 400px;

  ::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #fd3365;
  }

  ::-webkit-scrollbar-track-piece ::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media only screen and (min-width: 1440px) {
    position: absolute;
    min-height: 100%;
  }
`;

// const FeedbackContainer = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const RatingsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-right: auto;
// `;

// const RatingContainer = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   margin-right: 20px;
//   margin-bottom: 20px;
// `;

// const Number = styled.span`
//   display: inline-block;
//   width: 26px;
//   font-size: 2em;
//   background-color: white;
// `;

// const CommentsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// `;

// const CommentArea = styled.textarea`
//   width: 100%;
//   height: 100%;
//   margin-bottom: 20px;
// `;

// const ratings = [
//   { name: "audioQuality", label: "Calidad de vídeo" },
//   { name: "videoQuality", label: "Calidad de audio" },
//   { name: "explanationQuality", label: "Calidad de la explicación" },
// ];

const Class = () => {
  const routeParams = useParams();
  const [classes, setClasses] = useState([]);
  const [currentClass, setCurrentClass] = useState();
  // const [classFeedback, setClassFeedback] = useState({ comment: "" });

  useEffect(() => {
    getClassesRequest().promise.then((classes) => {
      setClasses(classes);
    });
  }, []);

  useEffect(() => {
    const nextClass = classes[parseInt(routeParams.id) - 1];
    if (nextClass) {
      setCurrentClass(nextClass);
      document.title = getClassTitle(nextClass);
    }
  }, [routeParams, classes]);

  // useEffect(() => {
  //   if (currentClass) {
  //     getClassFeedbackRequest(currentClass.id).promise.then(setClassFeedback);
  //   }
  // }, [currentClass]);

  // const onClassFeedbackUpdate = (nextClassFeedback) =>
  //   updateFeedbackRequest({ ...nextClassFeedback, classId: currentClass.id });

  const getClassTitle = ({ number, title }) => `${number} - ${title}`;

  // const onTextAreaChange = ({ target: { value } }) =>
  //   setClassFeedback({ ...classFeedback, comment: value });

  return (
    <Page>
      {currentClass && (
        <Container>
          <h1 style={{ color: "white" }}>{getClassTitle(currentClass)}</h1>
          <MediaContainer>
            <VideoContainer>
              <ReactPlayer
                className="react-player"
                url={currentClass.videoURL}
                width="100%"
                height="100%"
                controls
              />
            </VideoContainer>
            <VideoNavigationContainer>
              <RelativeContainer>
                <VideoNavigation>
                  {classes.map((currentVideo, index) => (
                    <Video
                      key={index}
                      active={currentClass.id === currentVideo.id}
                      {...currentVideo}
                    />
                  ))}
                </VideoNavigation>
              </RelativeContainer>
            </VideoNavigationContainer>
          </MediaContainer>
          {/* <FeedbackContainer>
            <RatingsContainer>
              {ratings.map((rating, index) => (
                <RatingContainer key={index}>
                  <span style={{ marginRight: 10 }}>{rating.label}</span>
                  <Rating
                    initialRating={classFeedback[rating.name]}
                    onChange={(nextRating) => {
                      const nextClassFeedback = {
                        ...classFeedback,
                        [rating.name]: nextRating,
                      };
                      setClassFeedback(nextClassFeedback);
                      onClassFeedbackUpdate(nextClassFeedback);
                    }}
                    emptySymbol={<Number>-</Number>}
                    fullSymbol={[1, 2, 3, 4, 5].map((n) => (
                      <Number>{n}</Number>
                    ))}
                  />
                </RatingContainer>
              ))}
            </RatingsContainer>
            <CommentsContainer>
              <p>
                Si tienes feedback, por favor escríbelo a continuación. Sólo lo
                vere yo.
              </p>
              <CommentArea
                placeholder="Escribe aquí tus comentarios o dudas"
                value={classFeedback.comment}
                onChange={onTextAreaChange}
              ></CommentArea>
              <Button onClick={() => onClassFeedbackUpdate(classFeedback)}>
                Enviar
              </Button>
            </CommentsContainer>
          </FeedbackContainer> */}
        </Container>
      )}
    </Page>
  );
};

export default Class;
