import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  border-bottom: 2px solid white;
  margin-top: 50px;
`;

const InputContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CustomInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: white;
  }
`;

const Input = ({ Icon, children, ...props }) => {
  return (
    <InputContainer>
      <InputContent>
        {typeof Icon === "object" && <Icon style={{ marginRight: 30 }} />}
        <CustomInput {...props} />
      </InputContent>
    </InputContainer>
  );
};

export default Input;
