function request(url, options = { method: "GET" }) {
  return cancellablePromise(
    fetch(process.env.REACT_APP_API_URL + url, {
      ...options,
      headers: getHeaders(options.headers),
    }).then((res) => {
      switch (res.status) {
        case 200:
          return res.json();
        case 204:
          return;
        case 401:
          if (window.location.pathname !== "/iniciar-sesion") {
            window.location = "/iniciar-sesion";
          }
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          return res.json().then((error) =>
            Promise.reject({
              status: res.status,
              error,
            })
          );

        default:
          return res.json().then(({ error }) =>
            Promise.reject({
              status: res.status,
              error,
            })
          );
      }
    })
  );
}

function getHeaders(headers) {
  const newHeaders = {
    "Accept-Language": "application/json",
    "Content-Type": "application/json",
    authorization:
      typeof localStorage !== "undefined" && localStorage.getItem("token"),
    ...headers,
  };

  return Object.keys(newHeaders)
    .filter((key) => typeof newHeaders[key] !== "undefined")
    .reduce((reducer, key) => ({ ...reducer, [key]: newHeaders[key] }), {});
}

export const cancellablePromise = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

export default request;
