import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../pages/Login";

const UnAuthApp = () => {
  return (
    <Router>
      <Switch>
        <Route path="/iniciar-sesion">
          <Login />
        </Route>
        <Redirect to="/iniciar-sesion" />
      </Switch>
    </Router>
  );
};

export default UnAuthApp;
