import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Class from "../pages/Class";
import { NavBar } from "../components";

const AuthApp = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/clase/:id">
          <Class />
        </Route>
        <Redirect to="/clase/1" />
      </Switch>
    </Router>
  );
};

export default AuthApp;
